import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Add Procurement Category Translation", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "SubClass", attrs: { "label": "Subclass", "data-source": _vm.subClassList, "placeholder": "Select Subclass", "source": "subClass", "source-label": "subClassDesc", "rules": "required", "span": 12, "form-item": "", "on-select-change": _vm.onSubClassChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "GPortFolioId", attrs: { "label": "Procurement Category", "data-source": _vm.globalPortfolioList, "option-filter": _vm.filterGlobalPortfolioOption, "placeholder": "Select Procurement Category", "source": "gPortfolioId", "source-label": "gPortfolio", "rules": "required", "span": 12, "value": _vm.gPortFolioId, "on-select-change": _vm.onPortfolioChange } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var CreateAssignGlobalPortfolio_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateAssignGlobalPortfolio",
  inject: ["crud", "redirectRoute"],
  mixins: [MyUtil],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      country: this.crud.getQueryString("CountryID").value,
      subClass: "",
      gPortFolioId: 33,
      subClassList: [],
      globalPortfolioList: []
    };
  },
  mounted() {
    this.visible = true;
  },
  created() {
    this.getCountries();
    this.getSubClassList();
    this.getGlobalPortfolioList();
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/ppv/global-portfolio-trans`, {
        countryId: this.country,
        subClass: this.subClass,
        gPortFolioId: this.gPortFolioId,
        updateType: "add"
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification.success({
          message: "Item Created!"
        });
      }).catch((err) => {
        this.isLoading = false;
        this.$notification["error"]({
          message: err.response.data.message || "Something went wrong, please try again!"
        });
      });
    },
    getSubClassList() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-sub-class?CountryID=${this.country}`).then((res) => {
        let { data } = res;
        this.subClassList = [...data];
      });
    },
    getGlobalPortfolioList() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-global-portfolio`).then((res) => {
        let { data } = res;
        this.globalPortfolioList = [...data];
      });
    },
    filterGlobalPortfolioOption(option) {
      return option.gPortfolio != 0;
    },
    onCountryChange(value) {
      this.country = value;
    },
    onSubClassChange(value) {
      this.subClass = value;
    },
    onPortfolioChange(value) {
      this.gPortFolioId = value;
    },
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onCancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1479f771", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateAssignGlobalPortfolio = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-countries" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-sub-class" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-global-portfolio" } }), _c("resource", { attrs: { "name": "ppv.global-portfolio-trans", "api-url": _vm.apiUrl, "redirect-route": "/ppv-actuals/assign-global-portfolio" } }, [_c("create-assign-global-portfolio")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateAssignGlobalPortfolio
  },
  data() {
    return {
      CreateAssignGlobalPortfolio,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };

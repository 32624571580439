var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import _ from "lodash";
const COUNTRY_NAME = {
  AU: "Australia",
  NZ: "New Zealand",
  JPN: "Japan",
  KOR: "Korea"
};
const apiUrl = "#{VUE_APP_API_URL}#";
var MyUtil = {
  data() {
    return {
      apiUrl,
      countries: [],
      currencies: [],
      rateTypes: [],
      listSubclass: [],
      ppvProcurementSpecialist: [],
      countryIdAU: 1,
      regex10digits5frac: {
        required: true,
        regexCustom: {
          regex: /^-?\d{1,10}(\.{1}\d{1,5}){0,1}$/,
          message: "format is invalid"
        }
      },
      regex9digits6frac: {
        required: true,
        regexCustom: {
          regex: /^-?\d{1,9}(\.{1}\d{1,6}){0,1}$/,
          message: "format is invalid"
        }
      },
      regex1digits7frac: {
        required: true,
        regexCustom: {
          regex: /^-?\d{1}(\.{1}\d{1,7}){0,1}$/,
          message: "format is invalid"
        }
      },
      regex12digits3frac: {
        required: true,
        regexCustom: {
          regex: /^-?\d{1,12}(\.{1}\d{1,3}){0,1}$/,
          message: "format is invalid"
        }
      },
      regex12digits3fracNotRequired: {
        regexCustom: {
          regex: /^-?\d{1,12}(\.{1}\d{1,3}){0,1}$/,
          message: "format is invalid"
        }
      },
      enitiesContext: JSON.parse(sessionStorage.getItem("ppvEntityContext"))
    };
  },
  computed: {
    selectedCountry() {
      const statusFilter = this.crud.getQueryString("CountryID");
      return statusFilter || { value: void 0 };
    },
    selectedProcurement() {
      const statusFilter = this.crud.getQueryString("gPortfolioId");
      return statusFilter || { value: void 0 };
    },
    selectedFacility() {
      const statusFilter = this.crud.getQueryString("Facility");
      return statusFilter || { value: void 0 };
    },
    selectedSubclass() {
      const statusFilter = this.crud.getQueryString("Subclass");
      return statusFilter || { value: void 0 };
    },
    selectedCurrency() {
      const statusFilter = this.crud.getFilters().get("Currency");
      return statusFilter || { value: void 0 };
    },
    selectedRateType() {
      const statusFilter = this.crud.getQueryString("RateType");
      return statusFilter || { value: void 0 };
    },
    country() {
      var value = this.crud.getQueryString("CountryID");
      if (value) {
        return value.value;
      }
      return this.countryIdAU;
    }
  },
  created() {
    if (this.country) {
      this.setAppContext(this.country);
    }
  },
  methods: {
    add(a, b) {
      return a + b;
    },
    setAppContext(countryId) {
      if (!countryId) {
        return;
      }
      if (countryId == 1) {
        this.$context.setAppContext(this.enitiesContext[0].entityId);
      } else if (countryId == 2) {
        this.$context.setAppContext(this.enitiesContext[1].entityId);
      }
    },
    generateCountryLabel(option) {
      const { country, countryName } = option;
      if (!countryName)
        return country;
      return `${country} - ${countryName}`;
    },
    getCurrency() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppvfw-currencies`).then((res) => {
        let { data: currencies } = res;
        currencies = [...currencies];
        this.currencies = currencies;
      });
    },
    getCountries() {
      this.axios.get(`${this.apiUrl}/ppv/common/countries`).then((res) => {
        let { data: countries } = res;
        countries = [...countries].slice(0, 2);
        this.countries = countries.map((country) => __spreadProps(__spreadValues({}, country), {
          countryName: COUNTRY_NAME[country.country]
        }));
      });
    },
    getPPVProcurementSpecialist() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-procurement-specialist`).then((res) => {
        this.ppvProcurementSpecialist = [...res.data];
      });
    },
    getListSubclass(countryid) {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-sub-class?CountryID=${countryid}`).then((res) => {
        this.listSubclass = [...res.data];
      });
    },
    getRateType() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppvfw-forex-rate-types`).then((res) => {
        let { data: rateTypes } = res;
        rateTypes = [...rateTypes];
        this.rateTypes = rateTypes;
      });
    },
    async onSelectFilterChange(value, key, clearFilter = true) {
      if (clearFilter === true) {
        this.crud.clearFilters();
        this.crud.deleteFilter("IsInactive");
      }
      if (key === "CountryID") {
        const enitiesContext = JSON.parse(sessionStorage.getItem("ppvEntityContext"));
        if (value.value == 1) {
          this.$context.setAppContext(enitiesContext[0].entityId);
        } else if (value.value == 2) {
          this.$context.setAppContext(enitiesContext[1].entityId);
        }
      }
      this.crud.setQueryString(key, value.value);
      const { pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: 1, pageSize });
      this.crud.fetchList();
    },
    onFilterChange(value, key) {
      this.crud.setQueryString(key, value);
    },
    onInputFilterChange: _.debounce(function(value, key) {
      this.crud.setQueryString(key, value);
    }, 400),
    onClearFilterClick() {
      this.crud.clearFilters();
      this.crud.deleteQueryStrings();
      this.crud.deleteFilter("IsInactive");
      this.crud.setQueryString("CountryID", this.countryIdAU);
      this.crud.fetchList();
    }
  }
};
export { MyUtil as M };
